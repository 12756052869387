import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { colors, medWrapper, H2Peach, B1Peach } from "../../styles/helpers"
import { Link } from "gatsby"

const ImageLinks = ({ data }) => {
  const imageTopLeftDisplay = getImage(
    data.imagesLinkTopImageLeft.localFile.childImageSharp.gatsbyImageData
  )
  const imageTopLefAlt = data.imagesLinkTopImageLeft.altText

  const imageTopcenterDisplay = getImage(
    data.imagesLinkTopImageCenter.localFile.childImageSharp.gatsbyImageData
  )
  const imageTopcenterAlt = data.imagesLinkTopImageCenter.altText

  const imageTopRightDisplay = getImage(
    data.imagesLinkTopImageRight.localFile.childImageSharp.gatsbyImageData
  )
  const imageTopRightAlt = data.imagesLinkTopImageRight.altText

  const imageBottomDisplay = getImage(
    data.imagesLinkBottomImage.localFile.childImageSharp.gatsbyImageData
  )
  const imageBottomAlt = data.imagesLinkBottomImage.altText
  return (
    <StyledSection>
      <div className="wrapper">
        <div className="top-images">
          <div className="top-images__image image-left">
            <GatsbyImage
              image={imageTopLeftDisplay}
              alt={imageTopLefAlt}
              layout="fullWidth"
              formats={["auto", "webp", "avif"]}
            />
          </div>
          <div className="top-images__image image-center">
            <GatsbyImage
              image={imageTopcenterDisplay}
              alt={imageTopcenterAlt}
              layout="fullWidth"
              formats={["auto", "webp", "avif"]}
            />
          </div>
          <div className="top-images__image image-right">
            <GatsbyImage
              image={imageTopRightDisplay}
              alt={imageTopRightAlt}
              layout="fullWidth"
              formats={["auto", "webp", "avif"]}
            />
          </div>
        </div>

        <div className="content">
          <div className="content__container">
            <div>
              <h2>{data.imagesLinkTitle}</h2>
            </div>
            <div dangerouslySetInnerHTML={{ __html: data.imagesLinkContent }} />
            <div>
              <Link to={`/${data.imagesLinkButtonSlug}`}>
                {data.imagesLinkButtonText}
              </Link>
            </div>
          </div>
          <div className="content__image">
            <GatsbyImage
              image={imageBottomDisplay}
              alt={imageBottomAlt}
              layout="fullWidth"
              formats={["auto", "webp", "avif"]}
            />
          </div>
        </div>
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  .wrapper {
    ${medWrapper};
  }

  .top-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    @media (min-width: 768px) {
      margin-bottom: 2.79rem;
    }

    &__image {
      width: 100%;
      margin-bottom: 2.79rem;

      @media (min-width: 768px) {
        width: calc(33.333333% - 1rem);
        margin-bottom: 0;
      }

      &.image-left {
        @media (min-width: 768px) {
        }
      }
      &.image-center {
        @media (min-width: 768px) {
          margin-left: 1.5rem;
        }
      }
      &.image-right {
        @media (min-width: 768px) {
          margin-left: 1.5rem;
        }
      }
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    &__container {
      width: 100%;
      background-color: ${colors.black};
      margin-bottom: 2.79rem;
      padding: 4rem 2rem;

      @media (min-width: 768px) {
        width: calc(66.666666% - 0.5rem);
        margin-bottom: 0;
        padding: 6rem 9rem;
      }

      h2 {
        ${H2Peach};
        margin-top: 0;
      }

      p {
        ${B1Peach};
      }

      a {
        ${B1Peach};
        text-transform: uppercase;

        &:hover {
          color: ${colors.colorAccent};
        }
      }
    }

    &__image {
      display: none;
      width: 100%;

      @media (min-width: 768px) {
        display: block;
        width: calc(33.333333% - 1rem);
        margin-left: 1.5rem;
      }
    }
  }
`

export default ImageLinks
