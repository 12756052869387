import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import React from "react"
import {
  B1DarkBlue,
  B1Brown,
  fonts,
  standardWrapper,
} from "../../styles/helpers"

const IconLinks = ({ data }) => {
  return (
    <StyledSection>
      <div className="wrapper">
        <div className="title">
          <h2>{data.iconLinksMainTitle}</h2>
        </div>
        <div className="icons">
          {data.iconLinks.map((icon, index) => {
            const imageDisplay = getImage(
              icon.buttonIcon.localFile.childImageSharp.gatsbyImageData
            )
            const imageAlt = icon.buttonIcon.altText
            const iconDisplay = icon.buttonActive ? (
              <IconLink
                firstlink={index === 0}
                secondlink={index === 1}
                thirdlink={index === 2}
                lastlink={index === 3}
                key={index}
              >
                <Link to={`/${icon.buttonSlug}`}>
                  <p>
                    <span className="link-icon">
                      <GatsbyImage
                        image={imageDisplay}
                        alt={imageAlt}
                        layout="fullWidth"
                        formats={["auto", "webp", "avif"]}
                      />
                    </span>
                    <span>{icon.buttonTitle}</span>
                  </p>
                </Link>
              </IconLink>
            ) : (
              <IconLink
                firstlink={index === 0}
                secondlink={index === 1}
                thirdlink={index === 2}
                lastlink={index === 3}
                key={index}
              >
                <p className="not-active">
                  <span className="link-icon">
                    <GatsbyImage
                      image={imageDisplay}
                      alt={imageAlt}
                      layout="fullWidth"
                      formats={["auto", "webp", "avif"]}
                    />
                  </span>
                  <span>{icon.buttonTitle}</span>
                </p>
              </IconLink>
            )
            return iconDisplay
          })}
        </div>
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  .wrapper {
    ${standardWrapper};
    z-index: 10;

    @media (min-width: 768px) {
    }

    @media (min-width: 1025px) {
      max-width: 94.9rem !important;
    }
  }

  .title {
    width: 100%;
    margin-bottom: 2rem;

    h2 {
      ${B1DarkBlue};
      font-family: ${fonts.fontPrimary};
      font-weight: bold;
      text-align: center;
    }
  }

  .icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
`

const IconLink = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: calc(50% - 1rem);
  margin-bottom: 2rem;
  margin-left: ${props =>
    props.firstlink ? "0rem" : props.thirdlink ? "0rem" : "2rem"};
  border: solid 1px rgba(195, 173, 139, 0.3);

  @media (min-width: 768px) {
    width: calc(25% - 1.5rem);
    margin-top: 2rem;
    margin-left: ${props => (props.firstlink ? "0rem" : "2rem")};
  }

  a {
    display: block;
    width: 100%;
    padding: 1.5rem 1rem;
    transition: all 0.3s ease-in;

    @media (min-width: 768px) {
    }

    &:hover {
      background-color: #ddd3c9;
    }
  }

  p {
    ${B1Brown};
    margin: 0;
    text-align: center;

    .link-icon {
      display: block;
      width: 10rem;
      margin: 0 auto;
    }

    &.not-active {
      width: 100%;
      padding: 1.5rem 1rem;
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
`

export default IconLinks
