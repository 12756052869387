import React from "react"
import styled from "styled-components"
import {
  H1White,
  standardWrapper,
  fontSizer,
  B1White,
} from "../../styles/helpers"
import HeroImage from "./HeroImage"

const Hero = ({ data }) => {
  const bgImg = data?.heroImage
  return (
    <StyledSection>
      {bgImg && <HeroImage bgImg={bgImg} />}
      <div className="home-hero-title">
        <h1>{data?.heroImageTitle}</h1>
        <p>
          Providing Lucid employees with benefits to support every stage of
          their lives.
        </p>
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.div`
  position: relative;
  width: 100%;
  height: 30rem;

  @media (min-width: 768px) {
    height: 40rem;
  }

  @media (min-width: 1025px) {
    height: 40rem;
  }

  .home-hero-title {
    ${standardWrapper};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;

    @media (min-width: 768px) {
      margin: auto;
    }

    @media (min-width: 1025px) {
      max-width: 94.9rem !important;
    }

    h1 {
      ${H1White};
      ${fontSizer(3.25, 8, 76.8, 150, 3)};
      margin: 0;
      margin-bottom: 2rem;
      padding: 0;
      width: 100%;
      text-align: center;
    }

    p {
      ${B1White};
      text-align: center;
    }
  }
`

export default Hero
