import React from "react"
import styled from "styled-components"
import { B1DarkBlue, B3DarkBlue, standardWrapper } from "../../styles/helpers"

const PageIntoText = ({ data }) => {
  return (
    <StyledDiv>
      <div className="wrapper">
        <div
          className="intro-big-content"
          dangerouslySetInnerHTML={{ __html: data.pageIntoTextBigText }}
        />
        <div
          className="intro-small-content"
          dangerouslySetInnerHTML={{ __html: data.pageIntoTextSmallText }}
        />
      </div>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  padding-top: 2.5rem;

  @media (min-width: 768px) {
    padding-top: 5rem;
  }

  @media (min-width: 1025px) {
    padding-top: 7.5rem;
  }

  .wrapper {
    ${standardWrapper};

    @media (min-width: 1025px) {
      max-width: 94.9rem !important;
    }
  }

  .intro-big-content {
    width: 100%;

    p {
      ${B3DarkBlue};
    }
  }

  .intro-small-content {
    width: 100%;

    p {
      ${B1DarkBlue};
    }
  }
`

export default PageIntoText
