import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { fonts, H3DarkBlue, medWrapper } from "../../styles/helpers"

const Quote = ({ data }) => {
  const imageDisplay = getImage(
    data.quoteIcon.localFile.childImageSharp.gatsbyImageData
  )
  const imageAlt = data.quoteIcon.altText
  return (
    <StyledDiv>
      <div className="wrapper">
        <div
          className="quote-content"
          dangerouslySetInnerHTML={{ __html: data.quoteContent }}
        />
        <div className="quote-auuthor">
          <p>{data.quoteAuthor}</p>
        </div>

        <div className="quote-icon">
          <GatsbyImage
            image={imageDisplay}
            alt={imageAlt}
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
          />
        </div>
      </div>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  padding: 5rem 0;
  background-color: rgba(221, 211, 201, 0.3);

  @media (min-width: 768px) {
    padding: 7.5rem 0 5rem;
  }
  @media (min-width: 1025px) {
    padding: 10rem 0 5rem;
  }

  .wrapper {
    ${medWrapper};
    position: relative;

    @media (min-width: 768px) {
      width: calc(100% - 17rem);
      margin: auto 15rem auto 2rem;
    }

    @media (min-width: 1025px) {
      width: calc(100%);
      margin: auto;
      max-width: 80rem !important;
    }
  }

  .quote-content {
    width: 100%;
    text-align: center;

    p {
      ${H3DarkBlue};
      margin-bottom: 0;
      font-family: ${fonts.fontSecondary};
      font-weight: 400;
    }
  }

  .quote-auuthor {
    width: 100%;
    text-align: center;

    p {
      ${H3DarkBlue};
      font-family: ${fonts.fontSecondary};
      font-weight: 400;
    }
  }

  .quote-icon {
    position: absolute;
    bottom: 0;
    right: -5rem;
    width: 20rem;

    @media (min-width: 768px) {
      right: -7.5vw;
    }

    @media (min-width: 1025px) {
      right: -5vw;
    }
  }
`

export default Quote
