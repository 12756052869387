import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, Link, useStaticQuery } from "gatsby"
import {
  B1DarkBlue,
  B2DarkBlue,
  Btn1One,
  colors,
  H2DarkBlue,
  medWrapper,
} from "../../styles/helpers"

const getData = graphql`
  {
    posts: allWpPost(sort: { order: ASC, fields: date }, limit: 3) {
      edges {
        node {
          id
          title
          slug
          post {
            postExcerpt
            featuredImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
        }
      }
    }
  }
`

const Resources = () => {
  const data = useStaticQuery(getData)
  const posts = data?.posts?.edges ? data.posts.edges : []

  if (posts.length === 0)
    return (
      <div>
        <p>Sorry no posts were found!</p>
      </div>
    )

  return (
    <StyledSection>
      <div className="wrapper">
        <div className="title">
          <h2>Resources</h2>
        </div>
        <div className="posts">
          {posts.map((post, index) => {
            const imgDisplay = getImage(
              post.node.post.featuredImage.localFile.childImageSharp
                .gatsbyImageData
            )
            const imgAlt = post.node.post.featuredImage.altText
            return (
              <Card
                firstpost={index === 0}
                id={post.node.id}
                to={`/resources/${post.node.slug}`}
              >
                <div className="image">
                  <div className="image__container">
                    <GatsbyImage
                      image={imgDisplay}
                      alt={imgAlt}
                      layout="fullWidth"
                      formats={["auto", "webp", "avif"]}
                    />
                  </div>
                </div>
                <div className="content">
                  <div className="content__title">
                    <h3>{post.node.title}</h3>
                  </div>
                  <div className="content__excerpt">
                    <p>{post.node.post.postExcerpt}</p>
                  </div>
                </div>
              </Card>
            )
          })}
        </div>
        <div className="more-links">
          <Link to="/resources">More Resources</Link>
        </div>
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  background-color: rgba(221, 211, 201, 0.4);

  .wrapper {
    ${medWrapper};
  }

  .title {
    width: 100%;
    text-align: center;

    h2 {
      ${H2DarkBlue};
      margin-bottom: 4rem;
    }
  }

  .posts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .more-links {
    width: 100%;
    padding: 6rem 0 4rem 0;
    text-align: center;

    a {
      ${Btn1One};
    }
  }
`

const Card = styled(Link)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 3.5rem;

  @media (min-width: 768px) {
    width: calc(33.333333% - 1rem);
    margin-bottom: 0;
    margin-left: ${props => (props.firstpost ? "0rem" : "1.5rem")};
  }

  .image {
    position: relative;
    width: 100%;
    height: 15rem;

    @media (min-width: 768px) {
      height: 24rem;
    }

    &__container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .gatsby-image-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100%);
        height: 100%;

        img {
          width: 100% !important;
        }
      }
    }
  }

  .content {
    width: 100%;
    padding: 3.1rem;
    flex-grow: 1;
    border: solid 1px ${colors.colorTertiary};
    background-color: ${colors.white};

    &__title {
      width: 100%;
      margin-bottom: 2rem;
      padding-bottom: 2rem;
      border-bottom: 0.1rem solid ${colors.colorPrimary};

      h3 {
        ${B1DarkBlue};
        margin: 0;
        font-weight: bold;
      }
    }

    &__excerpt {
      width: 100%;

      p {
        ${B2DarkBlue};
      }
    }
  }
`

export default Resources
