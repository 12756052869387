import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Hero from "../components/home/Hero"
import PageIntoText from "../components/home/PageIntoText"
import IconLinks from "../components/home/IconLinks"
import Quote from "../components/home/Quote"
import ImageLinks from "../components/home/ImageLinks"
import Resources from "../components/home/Resources"

const IndexPage = props => {
  const hero = props?.data?.hero?.template?.homePage
  const pageIntoText = props?.data?.pageIntoText?.template?.homePage
  const iconLinks = props?.data?.iconLinks?.template?.homePage
  const quote = props?.data?.quote?.template?.homePage
  const imageLinks = props?.data?.imageLinks?.template?.homePage
  const resourcesDisplay =
    props?.data?.resourcesDisplay?.template?.homePage?.displayResourcePosts

  return (
    <Layout>
      <Seo title="Home" />
      <Hero data={hero} />
      <PageIntoText data={pageIntoText} />
      <IconLinks data={iconLinks} />
      <Quote data={quote} />
      <ImageLinks data={imageLinks} />
      {resourcesDisplay && <Resources />}
    </Layout>
  )
}

export const homeQuery = graphql`
  {
    # seoInfo: wpPage(slug: { eq: "home" }) {
    #   seoFields {
    #     swbThemeDescription
    #     swbThemeMetaTitle
    #     swbThemeImage {
    #       localFile {
    #         relativePath
    #       }
    #     }
    #   }
    # }

    hero: wpPage(slug: { eq: "home" }) {
      template {
        ... on WpTemplate_Home {
          homePage {
            heroImageTitle
            heroImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }

    pageIntoText: wpPage(slug: { eq: "home" }) {
      template {
        ... on WpTemplate_Home {
          homePage {
            pageIntoTextBigText
            pageIntoTextSmallText
          }
        }
      }
    }

    iconLinks: wpPage(slug: { eq: "home" }) {
      template {
        ... on WpTemplate_Home {
          homePage {
            iconLinksMainTitle
            iconLinks {
              buttonTitle
              buttonIcon {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 900)
                  }
                }
              }
              buttonActive
              buttonSlug
            }
          }
        }
      }
    }

    quote: wpPage(slug: { eq: "home" }) {
      template {
        ... on WpTemplate_Home {
          homePage {
            quoteContent
            quoteAuthor
            quoteIcon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 900)
                }
              }
            }
          }
        }
      }
    }

    imageLinks: wpPage(slug: { eq: "home" }) {
      template {
        ... on WpTemplate_Home {
          homePage {
            imagesLinkTitle
            imagesLinkContent
            imagesLinkButtonText
            imagesLinkButtonSlug
            imagesLinkTopImageLeft {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
            imagesLinkTopImageCenter {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
            imagesLinkTopImageRight {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
            imagesLinkBottomImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
        }
      }
    }

    resourcesDisplay: wpPage(slug: { eq: "home" }) {
      template {
        ... on WpTemplate_Home {
          homePage {
            displayResourcePosts
          }
        }
      }
    }
  }
`

export default IndexPage
